import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import {
  URL_BOOKGROUP_PURCHASE_RECOMMENDATION_DOWNLOAD,
  URL_BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD,
} from '@mvb/tix-ui/constants';
import { waitFor } from '@ember/test-waiters';
import config from '@mvb/tix-ui/config/environment';
import Controller from '@ember/controller';

export default class PartyTitleUploadController extends Controller {
  @service api;
  @service file;
  @service intl;
  @service notifications;
  @service store;
  @service user;

  @tracked validationErrors;

  uploadUrl = `${config.APP.api}${URL_BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD}`;

  get uploadedFileName() {
    return this.user.selectedParty?.party?.prFileName;
  }

  @task
  @waitFor
  *downloadTask() {
    try {
      let { blob: file } = yield this.api.get(
        `${URL_BOOKGROUP_PURCHASE_RECOMMENDATION_DOWNLOAD}${this.user.selectedParty?.party?.mvbId}`
      );
      yield this.file.saveAs(file, this.uploadedFileName);
    } catch {
      let message = this.intl.t('partyTitleUpload.notification.downloadError');
      this.notifications.error(message, { autoClear: false });
    }

    return true;
  }

  @action
  download(event) {
    event.preventDefault();
    return this.downloadTask.perform();
  }

  @action
  onFileUploadError(response) {
    let responseMessage = response?.errors[0]?.meta?.messageText;

    let message =
      responseMessage === 'tooManyFlags'
        ? this.intl.t('partyTitleUpload.notification.tooManyFlags')
        : this.intl.t('partyTitleUpload.notification.uploadError');

    this.validationErrors = undefined;
    this.notifications.error(message, { autoClear: false });
  }

  @action
  onFileUploadSuccess(file, response) {
    if (response?.length > 0) {
      let message = this.intl.t('partyTitleUpload.notification.uploadSuccessWithWarnings');

      this.validationErrors = response;
      this.notifications.warning(message, { autoClear: false });
    } else {
      let message = this.intl.t('partyTitleUpload.notification.uploadSuccess');

      this.validationErrors = undefined;
      this.notifications.success(message, { autoClear: false });
    }

    if (this.user.selectedParty?.party?.id) {
      this.store.pushRecordAttributes('party', this.user.selectedParty.party.id, { prFileName: file.name });
    }
  }

  reset() {
    this.validationErrors = undefined;
  }
}
