import { didCancel, task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';

const CODELISTS_TO_LOAD = {
  17: { propertyName: 'contributorRoleCodes' },
  21: { propertyName: 'editionTypes' },
  25: { propertyName: 'illustrationTypes' },
  28: { propertyName: 'audienceTypes' },
  49: { propertyName: 'territoryTypes' },
  51: { propertyName: 'productRelationCodesList' },
  57: { propertyName: 'unpricedItemCodes' },
  58: { propertyName: 'priceTypes' },
  65: { propertyName: 'availabilityStates' },
  72: { propertyName: 'thesisTypes' },
  74: { propertyName: 'languagesList' },
  81: { propertyName: 'productContentTypes' },
  91: { propertyName: 'countryCodes' },
  144: { propertyName: 'drmCodes' },
  150: { propertyName: 'productTypes' },
  158: { propertyName: 'resourceContentType' },
  175: { propertyName: 'productTypeDetails' },
  184: { propertyName: 'warningTypes' },
  300: { propertyName: 'productBinding' },
  302: { propertyName: 'genrecodes' },
  309: { propertyName: 'ddcGroups' },
  393: { propertyName: 'themaClassification' },
  394: { propertyName: 'themaQualifierGeo' },
  395: { propertyName: 'themaQualifierLanguage' },
  396: { propertyName: 'themaQualifierTimePeriod' },
  397: { propertyName: 'themaQualifierEducationalPurpose' },
  398: { propertyName: 'themaQualifierInterestAge' },
  399: { propertyName: 'themaQualifierStyle' },
  503: { propertyName: 'seasonTypes' },
  504: { propertyName: 'publishingStatus' },
  506: { propertyName: 'highlightTypes' },
  507: { propertyName: 'highlightAudiences' },
  510: { propertyName: 'w2pTemplates' },
  511: { propertyName: 'previewTypes' },
  513: { propertyName: 'listingRecommendations' },
  514: { propertyName: 'marketingRecommendations' },
  516: { propertyName: 'orderQuantityUnits' },
  518: { propertyName: 'mediaIndexes' },
  519: { propertyName: 'seasonCodes' },
  520: { propertyName: 'marketingRecommendationBookingOptions' },
  521: { propertyName: 'ThaliaKeywords' },
  522: { propertyName: 'HugendubelAge' },
  523: { propertyName: 'HugendubelSeason' },
  530: { propertyName: 'HugendubelWKZPreise' },
  550: { propertyName: 'trcCategoryOfGoods' },
  551: { propertyName: 'trcMarketingRecommendations' },
};

export default class CodelistsService extends Service {
  @service api;
  @service errors;
  @service intl;

  @task({ drop: true })
  @waitFor
  *codeListsTask() {
    try {
      let joinedCodelists = Object.keys(CODELISTS_TO_LOAD).join(',');
      let codelists = yield this.api.get(`/codelists/multiple/${joinedCodelists}`);
      let data = {};

      if (Array.isArray(codelists)) {
        for (let codelist of codelists) {
          let { codes, property } = this._prepareCodelist(codelist);
          data[property] = codes;
        }
      }

      return data;
    } catch (error) {
      this.errors.handle(error);
    }

    return null;
  }

  codeIsPartOfList(code, category) {
    return this.getCodeList(category).some((item) => item.code === code);
  }

  getCodeList() {
    return this.getCodeListWithDeprecated(...arguments).filter((code) => !code.deprecated);
  }

  getCodeListOptions(category) {
    return this.getCodeList(category).map(({ code: value, description: text }) => ({ value, text }));
  }

  getCodeListOptionsWithDeprecated(category) {
    return this.getCodeListWithDeprecated(category).map(({ code: value, description: text }) => ({ value, text }));
  }

  getCodeListWithDeprecated(category) {
    return this.codeListsTask.lastSuccessful?.value?.[category] ?? [];
  }

  async load() {
    if (!this.codeListsTask.lastSuccessful?.value) {
      try {
        await this.codeListsTask.perform();
      } catch (error) {
        if (!didCancel(error)) {
          throw error;
        }
      }
    }
  }

  _prepareCodelist(codelist) {
    if (!codelist) {
      return {
        property: '',
        codes: [],
      };
    }

    let codelistMapping = CODELISTS_TO_LOAD[codelist.id];
    let codes = codelist.codes;
    let translations = {};

    for (let { code, description } of codes) {
      translations[`cl_${codelist.id}_${code}`] = description;
    }

    this.intl.addTranslations(this.intl.locale[0], translations);

    return {
      codes,
      property: codelistMapping.propertyName,
    };
  }
}
