import { all, task } from 'ember-concurrency';
import {
  CUSTOM_MARKETING_CONTENT_KEY,
  CUSTOM_MARKETING_CONTENT_MAX_MARKETING_RECOMMENDATIONS,
  CUSTOM_MARKETING_CONTENT_RECOMMENDATION_STATUS,
  PREVIEW_TYPE,
} from '@mvb/tix-ui/constants';
import { isEmpty, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { validateLength } from 'ember-changeset-validations/validators/index';
import { waitFor } from '@ember/test-waiters';
import CustomMarketingContentBaseService from '@mvb/tix-ui/services/custom-marketing-content-base';
import positiveIntegerWithDecimal from '@mvb/tix-ui/validators/positive-integer-with-decimal';
import validateUserHasDefaultDispolist from '@mvb/tix-ui/validators/user-has-default-dispolist';

export default class CustomMarketingContentHugendubelService extends CustomMarketingContentBaseService {
  @service abilities;
  @service collectionOfGoods;

  get assortments() {
    return this.collectionOfGoods.current?.specific ?? [];
  }

  /**
   * Determines for all the passed in records if they can be deleted or need any other updates before saving.
   *
   * For marketing-recommendations(-hugendubel): checks if both code and text are empty and sets the markedForDeletion flag to true if so.
   * We do not need to consider the info field, because we should not be able to save a record with only this value set.
   *
   * @param {*} recordsToSave array of all those relationships that should be saved separately
   * @param {CustomMarketingContentModel} model current CustomMarketingContent-record to be saved
   * @returns
   */
  async cleanupRecordsToSave(recordsToSave, model) {
    for (let record of recordsToSave) {
      if (['marketing-recommendation', 'marketing-recommendation-hugendubel'].includes(record.constructor.modelName)) {
        // set the parent if it exists
        let recordIsHugendubel = record.constructor.modelName === 'marketing-recommendation-hugendubel';
        if (recordIsHugendubel) {
          let marketingRecommendations = await model.get('marketingRecommendations');
          let parent = (await marketingRecommendations?.find((rec) => rec.position === record.position)) ?? null;
          let existingParent = record.get('parentMarketingRecommendation') ?? null;

          if (parent?.id !== existingParent?.id) {
            record.set('parentMarketingRecommendation', parent);
          }
        }

        // check if the record should be deleted
        let codeIsEmpty = !isPresent(record.code);
        let textIsEmpty = !isPresent(record.text);
        let statusIsEmpty = recordIsHugendubel ? !isPresent(record.status) : true;

        if (
          (codeIsEmpty && textIsEmpty && statusIsEmpty) ||
          (record.status === CUSTOM_MARKETING_CONTENT_RECOMMENDATION_STATUS.REJECTED &&
            record.code === null &&
            textIsEmpty)
        ) {
          record.set('markedForDeletion', true);
        }
      }
    }

    return recordsToSave;
  }

  determineIndexedMarketingRecommendationsForChangesetData(model, data) {
    let marketingRecommendations = model.marketingRecommendations;

    for (let i = 0; i < CUSTOM_MARKETING_CONTENT_MAX_MARKETING_RECOMMENDATIONS.HUGENDUBEL; i++) {
      let rec = marketingRecommendations?.find((loopRecommendation) => loopRecommendation.position === i + 1);

      data[`${CUSTOM_MARKETING_CONTENT_KEY.INDEXED_MARKETING_RECOMMENDATION}${i}`] = rec?.code ?? null;
      data[`${CUSTOM_MARKETING_CONTENT_KEY.INDEXED_MARKETING_RECOMMENDATION_NOTE}${i}`] = rec?.text ?? null;
      data[`${CUSTOM_MARKETING_CONTENT_KEY.INDEXED_MARKETING_RECOMMENDATION_BOOKING}${i}`] = rec?.info ?? null;
    }
  }

  determineIndexedMarketingRecommendationsHugForChangesetData(model, data) {
    let marketingRecommendationsHugendubel = model.hugMarketingRecommendations;

    for (let i = 0; i < CUSTOM_MARKETING_CONTENT_MAX_MARKETING_RECOMMENDATIONS.HUGENDUBEL; i++) {
      let rec = marketingRecommendationsHugendubel?.find((loopRecommendation) => loopRecommendation.position === i + 1);

      data[`${CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION}${i}`] = rec?.code ?? null;
      data[`${CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_NOTE}${i}`] = rec?.text ?? null;
      data[`${CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_BOOKING}${i}`] = rec?.info ?? null;

      // these are readonly fields, there might be changes for the correct display in the components, but we don't want to save them
      data[`${CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_STATUS}${i}`] = rec?.status ?? null;
      data[`${CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_EDITED_BY}${i}`] =
        rec?.editedBy ?? null;
      data[`${CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_EDITED_AT}${i}`] =
        rec?.editedAt ?? null;
    }
  }

  // overwrite abstract method from base service
  getDataForChangeset(model, isNew = false) {
    let {
      assortment,
      category,
      files,
      hugAges,
      hugComments,
      hugDispoListNotes,
      hugMarketingEvents,
      hugRegions,
      hugSeasons,
      id,
      marketingSubsidyStatuses,
      note,
      recommendedOrderAmount,
      sortedListingRecommendations: listing,
      sortedListingRecommendationsHugendubel: listingHug,
      tags,
      type,
    } = model;

    let data = {
      assortment: assortment?.get('value'),
      category: category?.get('code'),
      files: files ?? [],
      hasComparativeProducts: false, // component for comparative products handles the state of this, therefore defaults to false --> TODO: refactor with #30180
      id: isNew ? null : id,
      isNew,
      listingRecommendations: isEmpty(listing)
        ? []
        : listing.filter(Boolean).map((recommendation) => recommendation.code),
      note,
      recommendedOrderAmount,
      tags: tags ?? [],
      type,
    };

    this.determineIndexedMarketingRecommendationsForChangesetData(model, data);

    if (this.abilities.can('preview.viewAndEditHugendubelCentralFields', { type: PREVIEW_TYPE.HUGENDUBEL })) {
      data.hugAges = hugAges ?? [];
      data.hugComments = hugComments ?? [];
      data.hugDispoListNotes = hugDispoListNotes ?? [];
      data.hugMarketingEvents = hugMarketingEvents ?? [];
      data.hugRegions = hugRegions ?? [];
      data.hugSeasons = hugSeasons ?? [];

      data.marketingSubsidyStatuses = marketingSubsidyStatuses ?? [];

      data.hugListingRecommendations = isEmpty(listingHug)
        ? []
        : listingHug.filter(Boolean).map((recommendation) => recommendation.code);

      this.determineIndexedMarketingRecommendationsHugForChangesetData(model, data);
    }

    return data;
  }

  // overwrite abstract method from base service
  getValidationsForChangeset(model, defaultDispoList) {
    return {
      hugListingRecommendations: validateUserHasDefaultDispolist({
        defaultDispoList,
      }),
      recommendedOrderAmount: [positiveIntegerWithDecimal(), validateLength(this.maxLengthValidation)],
    };
  }

  /**
   * adds new or updates existing marketing recommendations (normal or HUG specific) with the passed data
   * no return value as it updates the recordsToSave array
   *
   * @param {*} change the change coming from the changeset
   * @param {*} property the name of the property that should be set/updated for the marketing-recommendation
   * @param {*} recordsToSave array of all the records that should be saved separately (because they are a relationship)
   * @param {*} model the custom-marketing-content model
   */
  @task
  @waitFor
  *addOrUpdateMarketingRecommendationRecordTask(change, property, recordsToSave, model) {
    let changeKeyArray = change.key.split('__');
    let index = Number(changeKeyArray[1]);
    let key = `${changeKeyArray[0]}__`;

    let isHugChange = new Set([
      CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION,
      CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_NOTE,
      CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_BOOKING,
      CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_STATUS,
    ]).has(key);

    let recommendations = yield isHugChange
      ? model.sortedMarketingRecommendationsHugendubel
      : model.sortedMarketingRecommendations;
    let recordModelName = isHugChange ? 'marketing-recommendation-hugendubel' : 'marketing-recommendation';
    let record =
      recommendations.find((rec) => rec.position === index + 1) ??
      this.store.createRecord(recordModelName, {
        customMarketingContent: model,
        position: index + 1,
      });

    // check for existing recommendation to update in recordsToSave
    let existingRecord = recordsToSave.find((saved) => {
      let hasSameId = saved.id === record.id;
      let hasSamePosition = saved.position === record.position;
      let hasSameModelName = saved.constructor.modelName === record.constructor.modelName;

      return (saved.id === null ? hasSameId && hasSamePosition : hasSameId) && hasSameModelName;
    });

    if (isPresent(existingRecord)) {
      existingRecord.set(property, change.value);
    } else {
      record.set(property, change.value);
      recordsToSave.push(record);
    }
  }

  // overwrite abstract task from base service
  @task
  @waitFor
  *saveRelatedEntitiesTask(model, recordsToSave) {
    //TODO should be done in chunks --> see ticket #30151

    // save marketing-recommendations first, then marketing-recommendations-hugendubel for setting correct parent relationship
    let hugMarketingRecommendationsToSave = recordsToSave.filter(
      (record) => record.constructor.modelName === 'marketing-recommendation-hugendubel'
    );
    let otherRecordsToSave = recordsToSave.filter(
      (record) => record.constructor.modelName !== 'marketing-recommendation-hugendubel'
    );

    otherRecordsToSave = yield this.cleanupRecordsToSave(otherRecordsToSave, model);
    yield all(
      otherRecordsToSave.map((record) => (record.get('markedForDeletion') ? record.destroyRecord() : record.save()))
    );

    hugMarketingRecommendationsToSave = yield this.cleanupRecordsToSave(hugMarketingRecommendationsToSave, model);
    yield all(
      hugMarketingRecommendationsToSave.map((record) =>
        record.get('markedForDeletion') ? record.destroyRecord() : record.save()
      )
    );
  }

  // overwrite abstract task from base service
  @task
  @waitFor
  *updateRecordsAndModelForSavingTask(changeset, model, recordsToSave) {
    try {
      for (let change of changeset.changes) {
        let { key, value } = change;

        // If we have indexed keys we need to remove the index part
        if (key.includes('__')) {
          let keyPart = key.split('__')[0];
          key = `${keyPart}__`;
        }

        switch (key) {
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_AGES:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_COMMENTS:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_DISPOLIST_NOTES:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_MARKETING_EVENTS:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_REGIONS:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_SEASONS:
          case CUSTOM_MARKETING_CONTENT_KEY.MARKETING_SUBSIDY_STATUSES: {
            // here the key is named the same way as the fields in the model
            // therefore we can set them directly as key
            // if we have any entries as a comma separated list, they should be saved as an array

            if ((CUSTOM_MARKETING_CONTENT_KEY.HUG_SEASONS || CUSTOM_MARKETING_CONTENT_KEY.HUG_AGES) && value === '') {
              value = [];
            }

            model.set(key, Array.isArray(value) ? value : value?.split(','));
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_LISTING_RECOMMENDATIONS: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'listing-recommendation-hugendubel',
              'hugListingRecommendations'
            );
            recordsToSave.push(...recommendations);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.LISTING_RECOMMENDATIONS: {
            let recommendations = yield this.consolidateRecommendationsTask.perform(
              changeset,
              model,
              'listing-recommendation'
            );
            recordsToSave.push(...recommendations);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.ASSORTMENT: {
            let assortment = this.assortments.find((assortment_) => assortment_.value === value);
            model.set('assortment', isPresent(assortment) ? assortment : null);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.CATEGORY: {
            let category = this.getCategoryCode(value);
            model.set('category', isPresent(category) ? category : null);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.INDEXED_MARKETING_RECOMMENDATION:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION: {
            yield this.addOrUpdateMarketingRecommendationRecordTask.perform(change, 'code', recordsToSave, model);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.INDEXED_MARKETING_RECOMMENDATION_NOTE:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_NOTE: {
            yield this.addOrUpdateMarketingRecommendationRecordTask.perform(change, 'text', recordsToSave, model);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.INDEXED_MARKETING_RECOMMENDATION_BOOKING:
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_BOOKING: {
            yield this.addOrUpdateMarketingRecommendationRecordTask.perform(change, 'info', recordsToSave, model);
            break;
          }
          case CUSTOM_MARKETING_CONTENT_KEY.HUG_INDEXED_MARKETING_RECOMMENDATION_STATUS: {
            yield this.addOrUpdateMarketingRecommendationRecordTask.perform(change, 'status', recordsToSave, model);
            break;
          }
          default: {
            break;
          }
        }
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }
}
