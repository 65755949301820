import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showFilter}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{this.onReset}}\n    {{did-update this.onValueArgumentChange @value}}\n    {{did-update this.loadThemaName @searchService.results}}\n    data-test-thema-filer\n  >\n    <:content>\n      {{t \"searchFilterProductThema.text.active\" name=this.themaName}}\n    </:content>\n  </Search::Filter>\n{{/if}}", {"contents":"{{#if this.showFilter}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{this.onReset}}\n    {{did-update this.onValueArgumentChange @value}}\n    {{did-update this.loadThemaName @searchService.results}}\n    data-test-thema-filer\n  >\n    <:content>\n      {{t \"searchFilterProductThema.text.active\" name=this.themaName}}\n    </:content>\n  </Search::Filter>\n{{/if}}","moduleName":"@mvb/tix-ui/components/search/filter/product-thema/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/product-thema/index.hbs"}});
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterProductThemaComponent extends SearchFilterBaseComponent {
  @service codelists;
  @service intl;

  @tracked themaName;
  @tracked passedInThemaCode;

  get showFilter() {
    return Object.values(this.args.value).some((v) => isPresent(v));
  }

  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();

    this.passedInThemaCode = Object.values(this.value)?.filter(Boolean)?.[0]?.replace('*', '') ?? this.value;
    this.themaName = this.passedInThemaCode;

    next(this, () => {
      this.loadThemaName();
    });
  }

  @action
  loadThemaName() {
    let themaCodelists = [
      'themaClassification',
      'themaQualifierGeo',
      'themaQualifierLanguage',
      'themaQualifierTimePeriod',
      'themaQualifierEducationalPurpose',
      'themaQualifierInterestAge',
      'themaQualifierStyle',
    ];

    let passedInThema = {
      code: null,
      description: null,
    };

    for (let themaCodelist of themaCodelists) {
      let thema = this.codelists.getCodeList(themaCodelist).find((item) => item.code === this.passedInThemaCode);

      if (thema) {
        passedInThema = thema;
        break;
      }
    }

    this.themaName =
      isPresent(passedInThema.code) && isPresent(passedInThema.description)
        ? this.intl.t('searchFilterProductThema.text.name', {
            code: passedInThema.code,
            value: passedInThema.description,
          })
        : this.value;
  }

  @action
  onReset() {
    this.value = this.defaultValue ?? null;
    this.search(this.value);
  }
}
